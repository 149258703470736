<template>
  <div  class="body">
    <div class="authentication">
      确认{{nameText}}本人操作
    </div>
    <div style="text-align: center;font-size:18px;margin-top:20px">请保持正脸在取景框中根据屏幕指示完成识别</div>
    <div class="scan-img">
      <van-image
          class="scan-img"
          :src="bgUrl"
      ></van-image>
    </div>
    <div class="posture">
      <van-image
          class="posture"
          :src="postureUrl"
      ></van-image>
    </div>
    <div class="button">
      <van-button round type="primary" size="large" @click="jumpAuthentication">开始人脸识别</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {

  },
  props: {},
  data() {
    return {
      nameText:'',
      form:{
        type:'身份证',
        nameText:'',
        numberText:'',
      },
    }
  },
  computed: {
    bgUrl(){
      return require("@/assets/img/head-sculpture.png")
    },
    postureUrl(){
      return require("@/assets/img/posture.png")
    }
  },
  watch: {
  },
  created() {
    let nameText1=this.$route.query.nameText.toString()
    let nameText = nameText1.length>2 ? nameText1.slice(0,1)+'*'+nameText1.slice(nameText1.length-1,nameText1.length) :nameText1.slice(0,1)+'*'
    this.nameText=nameText
    this.form.nameText=nameText1
    this.form.numberText=this.$route.query.numberText
  },
  mounted() {

  },
  onLoad(options) {

  },
  methods: {
    jumpAuthentication(){
      this.$router.push({path: '/facialComparison', query: {"numberText":this.form.numberText,"nameText":this.form.nameText}})
    }
  },
}
</script>
<style lang='scss' scoped>
.body{
  background-color: white;
}
.connect{
  text-align: center;
  margin-top:30px;
  color: blue;
}
.authentication{
  text-align: center;
  padding-top:40px;
  font-size: 25px;
  font-weight: bold;
}
.scan-img{
  opacity: 0.9;
  width: 20rem;
  height:20rem;
  margin: auto;
}
.posture{
  opacity: 0.9;
  width: 20rem;
  height:10rem;
  margin: auto;
}
.button{
  margin-top:30px;
}
</style>
